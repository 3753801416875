import React from "react"

import SEO from "../components/seo"
import LayoutInfo from "../components/layout-info"
import img1 from "./../images/infoforesatte1.jpg"

import styles from "./unsubscribe.module.scss"

class UnsubscribePage extends React.Component {

    render() {

        return (
            <LayoutInfo>
                <SEO title="Bekreftelse for avmelding"

                    description="Bekreftelse for avmelding av nyhetsbrev fra Leirskoledagboken"
                />

                <div className="row">
                    <div className="col">
                        <h1 className="card-title">Leirskole - Ta vare på leirskolestemningen!</h1>
                        <div>
                            <img className={` ${styles.infoPicture} rounded float-left mr-3 mb-2`} src={img1} alt="Illustrasjonsfoto" />
                            <p>
                                Du er nå avmeldt fra nyhetsbrevet til Leirskoledagboken.
                            </p>

                            
                        </div>
                    </div>
                </div>
            </LayoutInfo>
        )
    }

}
export default UnsubscribePage
